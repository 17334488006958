import Img from 'components/Img'
import Typography from 'rv-components-onze/src/Typography'

const MoneyProtection = () => {
  return (
    <section className="safety-money-protection container" data-show>
      <div className="safety-money-protection__title-wrapper">
        <Typography
          as="h2"
          variant="headline4"
          className="safety-money-protection__title">
          Seu dinheiro está duplamente protegido
        </Typography>
        <Img
          src="/img-safety-dots.png"
          className="safety-money-protection__dots-img"
          aria-hidden="true"
        />
      </div>
      <div className="safety-money-protection__text-wrapper">
        <div className="safety-money-protection__item">
          <Typography
            as="h3"
            variant="headline4"
            className="safety-money-protection__item-title">
            Seguradora
          </Typography>
          <Typography
            as="p"
            variant="body1"
            className="safety-money-protection__item-text">
            A seguradora é a instituição que garante que as regras 
            e operações do seu plano de previdência sejam executadas devidamente. 
            Toda seguradora também é regulada pela SUSEP. No caso da Onze, 
            a seguradora parceira é a Icatu.
          </Typography>
        </div>

        <div className="safety-money-protection__item">
          <Typography
            as="h3"
            variant="headline4"
            className="safety-money-protection__item-title">
            Administradora e Custodiante
          </Typography>
          <Typography
            as="p"
            variant="body1"
            className="safety-money-protection__item-text">
            A Onze faz a gestão inteligente do seu dinheiro por meio dos nossos
            fundos de investimento, mas a administração e custódia desses fundos
            são garantidos pelo Itaú e Intrag.
          </Typography>
          <Typography
            as="p"
            variant="body1"
            className="safety-money-protection__item-text">
            Nossa administradora parceira é a Intrag (Grupo Itaú), que tem o
            papel de zelar pelo bom funcionamento do fundo e garantir que tudo
            esteja de acordo com a legislação.
          </Typography>
          <Typography
            as="p"
            variant="body1"
            className="safety-money-protection__item-text">
            Nosso custodiante parceiro é o Itaú, que faz a guarda dos títulos e
            valores depositados em nome dos investidores, garantindo a sua
            propriedade.
          </Typography>
        </div>
      </div>
    </section>
  )
}

export default MoneyProtection
