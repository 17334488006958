import Typography from 'rv-components-onze/src/Typography'

const OnzeAndZurich = () => {
  return (
    <section className="onze-and-zurich container">
      <Typography
        as="h2"
        variant="headline5"
        className="onze-and-zurich__title">
        Onze {'&'} Icatu trabalhando pelo seu melhor futuro
      </Typography>
      <div className="onze-and-zurich__text-side">
        <Typography
          as="p"
          variant="body1"
          className="onze-and-zurich__paragraph">
          A Onze não está sozinha. Os planos de previdência da Onze são estruturados e 
          garantidos pela seguradora Icatu, enquanto nossos fundos previdenciários 
          são administrados pela Intrag - administradora de recursos pertencente ao banco Itaú, 
          que também é quem faz a custódia do dinheiro.
        </Typography>
        <Typography
          as="p"
          variant="body1"
          className="onze-and-zurich__paragraph">
          Caso a Onze deixe de existir, todos os recursos continuarão sob custódia do Itaú e administração da Intrag. 
          Além disso, os clientes continuarão tendo acesso aos seus planos de previdência da Icatu.
        </Typography>
      </div>
    </section>
  )
}

export default OnzeAndZurich
